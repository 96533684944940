<template>
  <div>
    <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
      <i v-if="edit" class="fas fa-edit"></i>
      <h6 v-else>Add New</h6>
    </button> -->

    <!-- Modal -->
    <div
      class="modal fade"
      :id="'exampleModal' + id"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="edit">Edit Rujukan</h5>
            <h5 class="modal-title" v-else>Buat Rujukan</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click.prevent="close()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form
              class="text-left"
              @submit.prevent="customUpload"
              id="modal-details"
            >
              <div class="form-group">
                <label for="formGroupExampleInput">Nama  <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  required
                  v-model="nama"
                />
              </div>
              <div class="form-group">
                <label for="example-date-input4">Tanggal Lahir <span class="text-danger">*</span></label>
                <input
                  class="form-control"
                  required
                  type="date"
                  v-model="tanggalLahir"
                />
              </div>
              <div class="form-group" v-if="!edit">
                <label for="exampleFormControlSelect1"
                  >Tujuan Rumah Sakit <span class="text-danger">*</span></label
                >
                <select class="form-control" v-model="tujuanTenant" required >
                  <option v-for="ten in listTenants" :key="ten.id" :value="ten"
                    ><h6>{{ ten.name }}</h6></option
                  >
                </select>
              </div>
              <div class="form-group">
                <label for="exampleFormControlSelect1">Tujuan Rujukan <span class="text-danger">*</span></label>
                <Dropdown
                  class="form-control"
                  :disabled="!tujuanTenant"
                  v-model="tujuanRujukan"
                  :options="listRujukans"
                  optionLabel="nama"
                  :filter="true"
                  dataKey="id"
                  placeholder="Tujuan Rujukan"
                  :showClear="true"
                >
                </Dropdown>
                <template v-if="!tujuanTenant">
                     <small class="text-danger">Pilih Tujuan Rumah sakit dahulu</small>
                </template>
               
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Telephone</label>
                <input
                  type="text"
                  class="form-control"
                  required
                  placeholder=""
                  v-model="telepon"
                />
              </div>
              <div class="form-group">
                <label for="formGroupExampleInput">Alamat Pasien <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  required
                  placeholder=""
                  v-model="alamat"
                />
              </div>
              <div class="form-group">
                <label for="exampleFormControlSelect1">Jenis Penjamin <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  v-model="jenisPenjamin"
                  required
                  :disabled="!tujuanTenant"
                >
                  <option
                    v-for="penja in listPenjamins"
                    :key="penja.id"
                    :value="penja"
                  >
                    {{ penja.nama }}
                  </option>
                </select>
                 <template v-if="!tujuanTenant">
                     <small class="text-danger"> Pilih Tujuan Rumah sakit dahulu</small>
                </template>
              </div>
              <div class="form-group">
                <label for="formGroupExampleInput2">Diagnosa / Keluhan <span v-if="isKategoriCheck" class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  :required="isKategoriCheck"
                  v-model="diagnosa"
                />
              </div>
              <div class="form-group" v-if="id">
                <label for="formGroupExampleInput2">Diagnosa Internal </label>
                <p>{{diagnosaInternal ? diagnosaInternal : '-'}}</p>
              </div>
              <div class="form-group">
                <label for="">Catatan</label>
                <textarea
                  name=""
                  id="catatan"
                  rows="4"
                  class="form-control"
                  style="margin: 4px"
                  v-model="catatan"
                >
                </textarea>
              </div>
            </form>
            <span>*Batas maksimal file 1MB</span>
            <input
              type="file"
              multiple
              accept="image/jpeg"
              @change="uploadImage"
            />

            <div v-for="(image, key) in images" :key="key">
              <div class="row mt-2">
                <div class="col">
                  <img
                    class="preview"
                    :ref="'image'"
                    style="height: 150px; width: auto"
                    :id="key"
                  />
                </div>
                <div class="col text-truncate">
                  {{ image.name }}
                </div>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-danger"
                    @click.prevent="remove(image, key)"
                  >
                    X
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click.prevent="close()"
            >
              <h6 class="mb-0">Close</h6>
            </button>
            <button type="submit" form="modal-details" class="btn btn-primary">
              <div
                class="spinner-border spinner-border-sm text-primary"
                role="status"
                v-if="loading"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <h6 class="mb-0" v-if="!loading">Simpan</h6>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import FileUpload from "primevue/fileupload";
import { mapState } from "vuex";
import Dropdown from "primevue/dropdown";

export default {
  name: "ModalAdd",
  data() {
    return {
      fileUploadUrl: `${process.env.VUE_APP_BASE_URL}/gallery/api/photos`,
      photos: [],
      // listTenants: [],
      alamat: "",
      diagnosa: "",
      diagnosaInternal: "",
      jenisPenjamin: {},
      nama: "",
      tanggalLahir: "",
      telepon: "",
      tujuanRujukan: null,
      catatan: "",
      fileUploaded: [],
      images: [],
      tujuanTenant: "",
      loading: false,
    };
  },
  components: {
    FileUpload,
    Dropdown,
  },
  props: {
    id: Number,
    info: Object,
    edit: Boolean,
  },
  computed: {
    ...mapState({
      listPenjamins: (state) => state.listPenjamins,
      listRujukans: (state) => state.listRujukans,
      latestParams: (state) => state.latestParams,
      listTenants: (state) => state.listTenants,
      kategoriUser: (state) => state.kategoriUser
    }),
    isKategoriCheck() {
      if(this.kategoriUser.name == 'AGEN ASURANSI' || 
      this.kategoriUser.name == 'AGEN PERUSAHAAN' || 
      this.kategoriUser.name == 'AGEN KOMUNITAS'
      ) {
        return false
      }
      return true 
    }
  },
  methods: {
    remove(image, key) {
      let a = this.photos.filter((im) => im.title !== image.name);
      let b = this.images.filter((_, index) => index !== key);
      this.images = b;
      this.photos = a;
      /* this.images.pop() */
      this.refToSrc();
    },
    turnToBase(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let base = reader.result;
          const img = base.split(",");
          resolve(img[1]);
        };
      });
    },
    async uploadImage(e) {
      let vm = this;
      var selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        this.images.push(selectedFiles[i]);

        let file = await this.turnToBase(selectedFiles[i]);
        this.photos.push({
          image: file,
          imageContentType: selectedFiles[i].type,
          title: selectedFiles[i].name,
          taken: new Date(selectedFiles[i].lastModifiedDate),
        });
      }
      this.refToSrc();
    },
    refToSrc() {
      for (let i = 0; i < this.images.length; i++) {
        if (this.edit && this.images[i].dataUrl) {
          setTimeout(() => {
            this.$refs.image[
              i
            ].src = `${process.env.VUE_APP_BASE_URL}/gallery/api/image/alt/${this.images[i].dataUrl}`;
          }, 100);
        } else {
          let reader = new FileReader();
          reader.onload = (e) => {
            this.$refs.image[i].src = reader.result;
          };

          reader.readAsDataURL(this.images[i]);
        }
      }
    },
    previewFiles(event) {
      // console.log(event.target.files);
    },
    onChildUpdate(newValue) {},
    process() {},
    buatPrime() {},
    onFileChange(file) {
      this.fileUploaded = file;
    },
    async blobToBase(pic) {
      const reader = new FileReader();
      reader.readAsDataURL(pic);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },
    async customUpload() {
      let vResult;
      this.loading = true;
      if (this.photos.length < 1) {
        const map2 = this.images.filter((w) => w.dataUrl);
        if (this.edit) {
          this.updateRujukan(map2);
        } else {
          this.create([]);
        }
      } else {
        axios
          .post(`/gallery/api/multi-photos`, this.photos)
          .then(({ data }) => {
            const map1 = data.map((x) => {
              return {
                data: x.image,
                dataContentType: x.imageContentType,
                dataUrl: x.alias,
                transaksiId: null,
                id: null,
              };
            });
            const map2 = this.images.filter((w) => w.dataUrl);
            let vResult = map1.concat(map2);

            if (this.edit) {
              this.updateRujukan(vResult);
            } else {
              this.create(vResult);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error("Upload Foto Gagal");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    create(images) {
      axios
        .post(
          `/api/transaksi-referrals`,
          {
            alamat: this.alamat,
            dataPenunjangs: [],
            diagnosa: this.diagnosa,
            jenisPenjamin: this.jenisPenjamin,
            nama: this.nama,
            tanggalLahir: this.tanggalLahir,
            telepon: this.telepon,
            tujuanRujukan: this.tujuanRujukan,
            catatan: this.catatan,
            dataPenunjangs: images,
          },
          {
            headers: {
              "X-TenantId": this.tujuanTenant.id,
            },
          }
        )
        .then(({ data }) => {
          this.$store.dispatch("GET_REFERRALS", {
            params: this.latestParams,
          });
          this.close();
          this.$toast.success("Rujukan Berhasil Ditambahkan");
          // console.log(data);
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Rujukan Gagal");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateRujukan(images) {
      (this.info.alamat = this.alamat),
        (this.info.diagnosa = this.diagnosa),
        (this.info.jenisPenjamin = this.jenisPenjamin),
        (this.info.nama = this.nama),
        (this.info.tanggalLahir = this.tanggalLahir),
        (this.info.telepon = this.telepon),
        (this.info.tujuanRujukan = this.tujuanRujukan),
        (this.info.catatan = this.catatan),
        (this.info.dataPenunjangs = images);

      axios
        .put(`/api/transaksi-referrals`, this.info, {
          headers: {
            "X-TenantId": this.info.tenantId,
          },
        })
        .then(({ data }) => {
          this.$store.dispatch("GET_REFERRALS", {
            params: this.latestParams,
          });
          this.close();
          this.$toast.success("Rujukan Berhasil Diubah");
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Rujukan Gagal Diubah");
        });
    },
    upload(event) {
      console.log(event, "upload");
    },
    select(event) {
      this.photos = this.$refs.dapetin.$data;
    },
    clear(event) {
      this.photos = [];
    },
    beforeUpload(request) {
      let token = localStorage.getItem("token");
      request.xhr.open("POST", this.fileUploadUrl);
      request.xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      return request;
    },
    close() {
      this.loading = false;
      $("#exampleModal" + this.id).modal("hide");
      this.$store.commit("SET_IS_MODAL_ADD", false);
      if (this.edit) {
        this.$emit("closeModal", false);
      }
    },
  },
  watch: {
    fileUploaded() {},
    tujuanTenant(val) {
      if (val) {
        // console.log(JSON.stringify(val))
        this.$store.dispatch("GET_RUJUKANS", val.id);
        this.$store.dispatch("GET_PENJAMINS", val.id);
      }
    },
  },
  mounted() {
    if (this.listTenants.length < 1) {
      this.$store.commit("SET_LIST_TENANTS", localStorage.token);
    }
    let vx = this;
    if (this.edit) {
      this.alamat = this.info.alamat;
      this.diagnosa = this.info.diagnosa;
      this.jenisPenjamin = this.info.jenisPenjamin;
      this.nama = this.info.nama;
      this.tanggalLahir = this.info.tanggalLahir;
      this.telepon = this.info.telepon;
      this.tujuanTenant = { id: this.info.tenantId };
      this.tujuanRujukan = this.info.tujuanRujukan;
      this.catatan = this.info.catatan;
      this.diagnosaInternal = this.info.diagnosaInternal
      if (this.info.dataPenunjangs.length > 0) {
        this.images = this.info.dataPenunjangs;
        this.refToSrc();
      }

      let b = {
        data: null,
        dataContentType: "image/png",
        dataUrl: "bb775239-3a65-4a91-b568-891a4a1fde6f",
        id: 476303,
        title: null,
        transaksiId: 476253,
      };
    }

    $("#exampleModal" + this.id).modal();

    $("#exampleModal" + this.id).on("hidden.bs.modal", function () {
      vx.close();
    });
  },
  beforeDestroy() {
    $("#exampleModal" + this.id).modal("hide");
  },
};
</script>

<style>
  .form-control:disabled, .form-control[readonly] {
    background-color: #ffffff;
    opacity: 1;
    content: 'Jenis Penjamin';
    border: 1px solid #e2e5e9 ;
  }

  select:disabled {
    color: #acb1b6;
  }
</style>
