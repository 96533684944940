<template>
  <div>
    <div
      class="modal fade"
      id="modalFilter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Urutkan & Filter</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click.prevent="close()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row text-left">
              <div class="col">
                <h6>Arah urutan</h6>
                <label class="" href="#"
                  ><input
                    type="radio"
                    name="customRadio"
                    value="desc"
                    v-model="getArah"
                  />&nbsp; Descending (turun)</label
                >
                <label class="" href="#"
                  ><input
                    type="radio"
                    name="customRadio"
                    value="asc"
                    v-model="getArah"
                  />&nbsp; ascending (naik)</label
                >
              </div>
              <div class="col">
                <h6>Urut berdasarkan</h6>
                <label class="" href="#"
                  ><input
                    type="radio"
                    name="customRadio1"
                    value="jejakTerakhirTimestamp"
                    v-model="getUrut"
                  />&nbsp; Update Terakhir</label
                >
                <br />
                <label class="" href="#"
                  ><input
                    type="radio"
                    name="customRadio1"
                    value="diagnosa"
                    v-model="getUrut"
                  />&nbsp; Diagnosa</label
                >
              </div>
            </div>
            <hr />
            <div class="text-left">
              <h6 class=" mb-2">Filter</h6>
              <label class="" href="#"
                ><input
                  type="checkbox"
                  name="checkbox"
                  value="PASIEN_BELUM_MASUK"
                  v-model="getFilters"
                />&nbsp; Pasien Belum Masuk</label
              ><br />
              <label class="" href="#"
                ><input
                  type="checkbox"
                  name="checkbox"
                  value="PASIEN_DATANG"
                  v-model="getFilters"
                />&nbsp; Pasien Datang</label
              ><br />
              <label class="" href="#"
                ><input
                  type="checkbox"
                  name="checkbox"
                  value="DIRUJUK_KETEMPAT_LAIN"
                  v-model="getFilters"
                />&nbsp; Dirujuk Ketempat Lain</label
              ><br />
              <label class="" href="#"
                ><input
                  type="checkbox"
                  name="checkbox"
                  value="DALAM_PERAWATAN"
                  v-model="getFilters"
                />&nbsp; Dalam Perawatan</label
              ><br />
              <label class="" href="#"
                ><input
                  type="checkbox"
                  name="checkbox"
                  value="PASIEN_PULANG"
                  v-model="getFilters"
                />&nbsp; Pasien Pulang</label
              ><br />
              <label class="" href="#"
                ><input
                  type="checkbox"
                  name="checkbox"
                  value="TIDAK_JADI_DATANG"
                  v-model="getFilters"
                />&nbsp; Tidak Jadi Datang</label
              ><br />
              <!-- <label class="" href="#"><input type="checkbox" name="checkbox" value="DALAM_PROSES_PEMBAYARAN" v-model="getFilters">&nbsp; Dalam Proses Pembayaran</label><br>
            <label class="" href="#"><input type="checkbox" name="checkbox" value="SUDAH_TERBAYARKAN" v-model="getFilters">&nbsp; Sudah Terbayarkan</label><br> -->
              <label class="" href="#"
                ><input
                  type="checkbox"
                  name="checkbox"
                  value="CLOSED"
                  v-model="getFilters"
                />&nbsp; Closed</label
              ><br />
            </div>
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal" @click.prevent="close()">Close</button>
            <button type="button" class="btn btn-primary" @click.prevent="customUpload()" >Save changes</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ModalFilter",
  data() {
    return {
      // "DIRUJUK_KETEMPAT_LAIN","DALAM_PERAWATAN","TIDAK_JADI_DATANG","PASIEN_DATANG", "PASIEN_PULANG", "DALAM_PROSES_PEMBAYARAN", "SUDAH_TERBAYARKAN"
      parr: "",
      getSize: 15,
      page: 1
    };
  },
  computed: {
    getFilters: {
      get() {
        return this.$store.state.getFilters
      },
      set(newVal) {
        return this.$store.commit('SET_GET_FILTER', newVal)
      }
    },
    getUrut: {
      get() {
        return this.$store.state.getUrut
      },
      set(newVal) {
        return this.$store.commit('SET_GET_URUT', newVal)
      }
    },
    getArah: {
      get() {
        return this.$store.state.getArah
      },
      set(newVal) {
        return this.$store.commit('SET_GET_ARAH', newVal)
      }
    },
    createParams() {
      var params = new URLSearchParams();
      const map1 = this.getFilters.map((x) => {
        params.append("jejakTerakhirStage.in", x);
      });
      // let sort = this.getUrut.join();
      let sort = `${this.getUrut},${this.getArah}`;
      // console.log(sort, 'ahoyy');
      params.append("sort", sort);
      params.append("page", this.page - 1);
      params.append("size", this.getSize);

      return params;
    },
  },
  watch: {
    getFilters() {
      this.page = 1;
      this.createParams.set("page", 0);
      this.$store.dispatch("GET_REFERRALS", {
        params: this.createParams,
      });
    },
    getArah() {
      this.$store.dispatch("GET_REFERRALS", {
        params: this.createParams,
      });
    },
    getUrut() {
      this.$store.dispatch("GET_REFERRALS", {
        params: this.createParams,
      });
    },
  },
  methods: {
    close() {
      $("#modalFilter").modal("hide");
      this.$store.commit("SET_MODAL_FILTER", false);
    },
  },
  mounted() {
    let vx = this;
    $("#modalFilter").modal();
    $("#modalFilter").on("hidden.bs.modal", function() {
      vx.close();
    });
  },
};
</script>

<style></style>
